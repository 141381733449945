<template>
  <div class="pcontent">
    <div class="pform" style="
            background-color: #ffffff;
            box-shadow: 0px 0px 17px -8px rgba(14, 155, 210, 20);
            border-radius: 8px;
          ">
      <div class="pform--header">
        <div @click="back" class="pform--header--back">返回</div>
        <div class="pform--header--title">{{ pageTitles[type] }}</div>
      </div>
      <div class="pform--title">未成年人信息</div>
      <div class="pform--content">
        <div class="pform--content--row">
          <div class="pform--content--row--label">
            <span style="color: red">*</span>姓名：
          </div>
          <el-input class="pform--content--row--value" clearable v-model="form.nonageName"
            placeholder="请输入未成年人的姓名"></el-input>
        </div>
        <div class="pform--content--row" v-if="type == 1">
          <div class="pform--content--row--label">
            <span style="color: red" v-if="$route.query.type == 1">*</span>在原案中的诉讼关系：
          </div>
          <el-select class="pform--content--row--value" v-model="form.lawsuitStatus" placeholder="请选择">
            <el-option v-for="item in ['被害人', '犯罪嫌疑人或被告人']" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="pform--content--row" v-if="type == 2">
          <div class="pform--content--row--label">未成年身份：</div>
          <el-select class="pform--content--row--value" v-model="form.nonageIdentity" placeholder="请选择">
            <el-option v-for="item in ['被害人', '被害人子女']" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="pform--content--row">
          <div class="pform--content--row--label">性别：</div>
          <el-select class="pform--content--row--value" v-model="form.nonageSex" placeholder="请选择">
            <el-option v-for="item in ['男', '女']" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="pform--content--row">
          <div class="pform--content--row--label">曾用名：</div>
          <el-input class="pform--content--row--value" clearable v-model="form.formerName" placeholder="曾用名"></el-input>
        </div>
        <div class="pform--content--row">
          <div class="pform--content--row--label">绰号：</div>
          <el-input class="pform--content--row--value" clearable v-model="form.nickname" placeholder="绰号"></el-input>
        </div>
        <div class="pform--content--row">
          <div class="pform--content--row--label">
            <span style="color: red">*</span>证件类型：
          </div>
          <el-select class="pform--content--row--value" v-model="form.nonageDocumentType" placeholder="请选择">
            <el-option v-for="item in [
              '居民身份证',
              '户口本',
            ]" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="pform--content--row">
          <div class="pform--content--row--label">
            <span style="color: red">*</span>证件号码：
          </div>
          <el-input class="pform--content--row--value" v-model="form.nonageDocumentNumber" type="text"
            placeholder="证件号码"></el-input>
        </div>
        <div class="pform--content--row">
          <div class="pform--content--row--label">出生日期：</div>
          <el-date-picker class="pform--content--row--value" v-model="form.dateOfBirth" type="date" clearable
            @change="dateOfBirthChange" placeholder="出生日期">
          </el-date-picker>
        </div>
        <div class="pform--content--row">
          <div class="pform--content--row--label">民族：</div>
          <el-select class="pform--content--row--value" v-model="form.nonageNation" placeholder="请选择">
            <el-option v-for="item in nationDict" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="pform--content--row">
          <div class="pform--content--row--label">国籍：</div>
          <el-select class="pform--content--row--value" v-model="form.nonageNationality" placeholder="请选择">
            <el-option v-for="item in ['中国', '其他']" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="pform--content--row">
          <div class="pform--content--row--label">户籍所在地：</div>
          <el-cascader class="pform--content--row--value" placeholder="请选择" size="large" :options="options"
            v-model="censusRegisterSeatOptions" @change="censusRegisterSeatChange">
          </el-cascader>
        </div>
        <div class="pform--content--row">
          <div class="pform--content--row--label">住所地：</div>
          <el-cascader class="pform--content--row--value" placeholder="请选择" size="large" :options="options"
            v-model="localOptions" @change="localHandleChange">
          </el-cascader>
        </div>
        <div class="pform--content--row">
          <div class="pform--content--row--label">住所地详细地址：</div>
          <el-input class="pform--content--row--value" v-model="form.detailedAddressOf" placeholder="住所地详细地址"></el-input>
        </div>
        <div class="pform--content--row">
          <div class="pform--content--row--label">工作单位/学校：</div>
          <el-input class="pform--content--row--value" v-model="form.schoolName" placeholder="工作单位/学校"></el-input>
        </div>
        <div class="pform--content--row">
          <div class="pform--content--row--label">工作单位/学校所在地：</div>
          <el-cascader class="pform--content--row--value" placeholder="请选择" size="large" :options="options"
            v-model="schoolAddressOptions" @change="schoolAddressChange">
          </el-cascader>
        </div>
        <div class="pform--content--row">
          <div class="pform--content--row--label">法定代理人：</div>
          <el-input class="pform--content--row--value" v-model="form.legalRepresentative" placeholder="法定代理人"></el-input>
        </div>
        <div class="pform--content--row">
          <div class="pform--content--row--label">监护情况：</div>
          <el-select class="pform--content--row--value" v-model="form.guardianshipIs" placeholder="请选择">
            <el-option v-for="item in [
              '父母监护',
              '祖父母或外祖父母监护',
              '其他成年亲属',
              '村委会或居委会',
              '未成年人保护组织',
              '无人监护',
              '民政部门',
              '单亲监护(父/母)',
              '其他',
            ]" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="pform--content--row">
          <div class="pform--content--row--label">案发时年龄：</div>
          <el-select class="pform--content--row--value" v-model="form.ageWhenCrimeHappened" placeholder="请选择">
            <el-option v-for="item in [
              '不满12周岁',
              '已满12周岁不满14周岁',
              '已满14周岁不满16周岁',
              '已满16周岁不满18周岁',
            ]" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="pform--title">
        <!--        控告人信息-->
        {{ action2Titles[type] }}
      </div>
      <div class="pform--content">
        <div class="pform--content--row">
          <div class="pform--content--row--label">
            <span style="color: red">*</span>姓名或单位名称：
          </div>
          <el-input class="pform--content--row--value" clearable v-model="form.applyName"
            placeholder="请输入您的姓名"></el-input>
        </div>
        <div class="pform--content--row">
          <div class="pform--content--row--label">
            <span style="color: red" v-if="$route.query.type == 1">*</span>与未成年人的关系：
          </div>
          <el-select class="pform--content--row--value" v-model="form.applyRelation" placeholder="请选择">
            <el-option v-for="item in [
              '未成年人本人',
              '法定代理人',
              '其他近亲属',
              '代理律师',
              '其他人员',
            ]" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="pform--content--row">
          <div class="pform--content--row--label">性别：</div>
          <el-select class="pform--content--row--value" v-model="form.applySex" placeholder="请选择">
            <el-option v-for="item in ['男', '女']" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="pform--content--row">
          <div class="pform--content--row--label">
            <span style="color: red">*</span>证件类型：
          </div>
          <el-select class="pform--content--row--value" v-model="form.applyDocumentType" placeholder="请选择">
            <el-option v-for="item in [
              '居民身份证',
              '军官证',
              '士兵证',
              '警官证',
              '工作证',
              '护照',
              '户口本',
              '律师执业证',
              '其他证件',
            ]" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="pform--content--row">
          <div class="pform--content--row--label">
            <span style="color: red">*</span>证件号码：
          </div>
          <el-input class="pform--content--row--value" v-model="form.applyDocumentNumber" placeholder="证件号码"></el-input>
        </div>
        <div class="pform--content--row">
          <div class="pform--content--row--label">民族：</div>
          <el-select class="pform--content--row--value" v-model="form.applyNation" placeholder="请选择">
            <el-option v-for="item in nationDict" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="pform--content--row">
          <div class="pform--content--row--label">国籍：</div>
          <el-select class="pform--content--row--value" v-model="form.applyNationality" placeholder="请选择">
            <el-option v-for="item in ['中国', '其他']" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="pform--content--row" v-if="type > 0">
          <div class="pform--content--row--label">职务：</div>
          <el-input class="pform--content--row--value" v-model="form.applyPosition" placeholder="请输入"></el-input>
        </div>
        <div class="pform--content--row">
          <div class="pform--content--row--label">所在地区：</div>
          <el-cascader class="pform--content--row--value" placeholder="请选择" size="large" :options="options"
            v-model="applyInAreaOptions" @change="applyInAreaChange">
          </el-cascader>
        </div>
        <div class="pform--content--row">
          <div class="pform--content--row--label">单位/住址：</div>
          <el-input class="pform--content--row--value" v-model="form.applyAddress" placeholder="单位/住址"></el-input>
        </div>
        <div class="pform--content--row">
          <div class="pform--content--row--label">身份：</div>
          <el-select class="pform--content--row--value" v-model="form.applyIdentity" placeholder="请选择">
            <el-option v-for="item in [
              '工人',
              '农民',
              '进城务工人员',
              '士兵',
              '学生',
              '非国有公司企事业单位',
              '中介机构',
              '个体劳动者',
              '无业人员',
              '服刑罪犯',
              '劳教人员',
              '港澳人员',
              '台湾人员',
              '外国人',
              '共产党机关',
              '权力机关',
              '政协机关',
              '行政机关',
              '银行',
              '保险机构',
              '证券机构',
              '非银行融资机构',
              '司法机关',
              '人民团体',
              '军事机关',
              '国有公司企业',
              '国有事业单位',
              '委派到非国有单位',
              '其他依法从事公务',
              '其他',
            ]" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="pform--content--row">
          <div class="pform--content--row--label">
            <span style="color: red">*</span>联系电话：
          </div>
          <el-input class="pform--content--row--value" v-model="form.applyMobile" placeholder="联系电话"></el-input>
        </div>
      </div>

      <!-- 被控告人 -->
      <div class="pform--title" v-if="type < 2">
        {{ action3Titles[type] }}
      </div>
      <div class="pform--content" v-if="type < 2">
        <div class="pform--content--row">
          <div class="pform--content--row--label">
            <span style="color: red">*</span>
            {{ type == 0 ? "姓名：" : "姓名或单位名称：" }}
          </div>
          <el-input class="pform--content--row--value" clearable v-model="form.personName" placeholder="请输入"></el-input>
        </div>
        <div class="pform--content--row">
          <div class="pform--content--row--label">
            <span style="color: red">*</span>性别：
          </div>
          <el-select class="pform--content--row--value" v-model="form.personSex" placeholder="请选择">
            <el-option v-for="item in ['性别不详', '男', '女']" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="pform--content--row">
          <div class="pform--content--row--label">职务：</div>
          <el-input class="pform--content--row--value" v-model="form.personPosition" placeholder="职务"></el-input>
        </div>
        <div class="pform--content--row">
          <div class="pform--content--row--label">身份：</div>
          <el-select class="pform--content--row--value" v-model="form.personIdentity" placeholder="请选择">
            <el-option v-for="item in [
              '工人',
              '农民',
              '进城务工人员',
              '士兵',
              '学生',
              '非国有公司企事业单位',
              '中介机构',
              '个体劳动者',
              '无业人员',
              '服刑罪犯',
              '劳教人员',
              '港澳人员',
              '台湾人员',
              '外国人',
              '共产党机关',
              '权力机关',
              '政协机关',
              '行政机关',
              '银行',
              '保险机构',
              '证券机构',
              '非银行融资机构',
              '司法机关',
              '人民团体',
              '军事机关',
              '国有公司企业',
              '国有事业单位',
              '委派到非国有单位',
              '其他依法从事公务',
              '其他',
            ]" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="pform--content--row">
          <div class="pform--content--row--label">其他关注身份：</div>
          <el-select class="pform--content--row--value" v-model="form.personOtherIdentity" placeholder="请选择">
            <el-option v-for="item in [
              '中介机构',
              '银行',
              '保险机构',
              '证券机构',
              '非银行金融机构',
              '检察机关内设机构领导',
              '非上述关注身份',
            ]" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="pform--content--row">
          <div class="pform--content--row--label">户籍/单位所在地：</div>
          <el-cascader class="pform--content--row--value" placeholder="请选择" size="large" :options="options"
            v-model="personLocalOptions" @change="personLocalChange">
          </el-cascader>
        </div>
        <div class="pform--content--row">
          <div class="pform--content--row--label">住址：</div>
          <el-input class="pform--content--row--value" v-model="form.personAddress" placeholder="住址"></el-input>
        </div>
        <div class="pform--content--row">
          <div class="pform--content--row--label">
            <span style="color: red">*</span>工作单位：
          </div>
          <el-input class="pform--content--row--value" v-model="form.personUnits" placeholder="工作单位"></el-input>
        </div>
      </div>

      <div class="pform--title">
        {{ action4Titles[type] }}
      </div>
      <div class="pform--content">
        <template v-if="type == 0">
          <div class="pform--content--row">
            <div class="pform--content--row--label">
              <span style="color: red">*</span>案发地：
            </div>
            <el-cascader class="pform--content--row--value" placeholder="请选择" size="large" :options="options"
              v-model="contentAddressOptions" @change="contentAddressChange">
            </el-cascader>
          </div>
          <div class="pform--content--row">
            <div class="pform--content--row--label">控告事项发生时间：</div>
            <el-date-picker class="pform--content--row--value" v-model="form.contentTime" type="date" clearable
              @change="dateOfBirthChange" placeholder="请选择">
            </el-date-picker>
          </div>
          <div class="pform--content--row">
            <div class="pform--content--row--label">
              <span style="color: red">*</span>请选择接收的检察院：
            </div>
            <el-cascader class="pform--content--row--value" placeholder="请选择" size="large" :options="onlyRegionData"
              v-model="contentDealOptions" @change="contentDealChange">
            </el-cascader>
          </div>
          <div class="pform--content--row">
            <div class="pform--content--row--label">控告刑事案由：</div>
            <el-select class="pform--content--row--value" v-model="form.criminalCasesBy" placeholder="请选择">
              <el-option v-for="item in [
                '强奸罪',
                '强制猥亵、侮辱罪',
                '儿童罪',
                '收买被拐卖的妇女儿童罪',
                '虐待罪',
                '遗弃罪',
                '拐骗儿童罪',
                '组织残疾人、儿童乞讨罪',
                '组织未成年人进行违反治安管理活动罪',
                '组织、强迫、引诱、容留、介绍卖淫罪',
                '传播淫秽物品罪',
                '组织淫秽表演罪',
                '虐待被监管人员罪',
                '其他',
              ]" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </div>
          <div class="pform--content--row">
            <div class="pform--content--row--label">控告其他刑事案由：</div>
            <el-select class="pform--content--row--value" v-model="form.otherCriminalCasesBy" placeholder="请选择">
              <el-option v-for="item in [
                '强奸罪',
                '强制猥亵、侮辱罪',
                '儿童罪',
                '收买被拐卖的妇女儿童罪',
                '虐待罪',
                '遗弃罪',
                '拐骗儿童罪',
                '组织残疾人、儿童乞讨罪',
                '组织未成年人进行违反治安管理活动罪',
                '组织、强迫、引诱、容留、介绍卖淫罪',
                '传播淫秽物品罪',
                '组织淫秽表演罪',
                '虐待被监管人员罪',
                '其他',
              ]" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </div>
          <div class="pform--content--row pform--content--row_lg">
            <div class="pform--content--row--label">
              <span style="color: red">*</span>控告内容：
            </div>
            <el-input class="pform--content--crow--value" type="textarea" :rows="6" placeholder="请输入内容"
              v-model="form.contentDesc" maxlength="2000" show-word-limit>
            </el-input>
          </div>

          <div class="pform--content--row pform--content--row_lg">
            <div class="pform--content--row--label">上传控告材料：</div>
            <el-upload action="https://fileprod.skillhub.top/file/imageFileUpload/" list-type="picture-card"
              class="pform--content--crow--value" :multiple="true" :limit="6" accept=".jpg,.png,.jpeg"
              :on-success="chargedMaterialOnSuccess" :on-remove="chargedMaterialOnRemove">
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
          <div class="pform--content--row pform--content--row_lg">
            <div class="pform--content--row--label">上传证据材料：</div>
            <el-upload action="https://fileprod.skillhub.top/file/imageFileUpload/" list-type="picture-card"
              class="pform--content--crow--value" :multiple="true" :limit="6" accept=".jpg,.png,.jpeg"
              :on-success="evidenceMaterialOnSuccess" :on-remove="evidenceMaterialOnRemove">
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
          <div class="pform--content--row pform--content--row_lg">
            <div class="pform--content--row--label">上传其他材料：</div>
            <el-upload action="https://fileprod.skillhub.top/file/imageFileUpload/" list-type="picture-card"
              class="pform--content--crow--value" :multiple="true" :limit="6" accept=".jpg,.png,.jpeg"
              :on-success="otherMaterialsOnSuccess" :on-remove="otherMaterialsOnRemove">
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
        </template>
        <template v-if="type == 1">
          <div class="pform--content--row">
            <div class="pform--content--row--label">
              <span style="color: red">*</span>请选择接收的检察院：
            </div>
            <el-cascader class="pform--content--row--value" placeholder="请选择" size="large" :options="onlyRegionData"
              v-model="contentDealOptions" @change="contentDealChange">
            </el-cascader>
          </div>
          <div class="pform--content--row">
            <div class="pform--content--row--label">申诉所涉机关类型：</div>
            <el-select class="pform--content--row--value" v-model="form.authorityType" placeholder="请选择">
              <el-option v-for="item in [
                '公安机关',
                '检察机关',
                '审判机关',
                '国家安全机关',
                '司法行政机关',
                '其他',
              ]" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </div>
          <div class="pform--content--row">
            <div class="pform--content--row--label">申诉所涉机关名称：</div>
            <el-input class="pform--content--row--value" v-model="form.authorityName" placeholder="请输入"></el-input>
          </div>
          <div class="pform--content--row">
            <div class="pform--content--row--label">案发地：</div>
            <el-cascader class="pform--content--row--value" placeholder="请选择" size="large" :options="options"
              v-model="contentAddressOptions" @change="contentAddressChange">
            </el-cascader>
          </div>
          <div class="pform--content--row">
            <div class="pform--content--row--label">申诉类别：</div>
            <el-select class="pform--content--row--value" v-model="form.complaintCategory" placeholder="请选择">
              <el-option v-for="item in [
                '不服不立案',
                '不服不批捕',
                '不服不起诉',
                '不服附条件不起诉',
                '不服逮捕',
                '不服起诉',
                '不服刑事判决',
                '未执行未成年人特殊司法制度',
                '其他',
              ]" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </div>
          <div class="pform--content--row">
            <div class="pform--content--row--label">原案发生时间：</div>
            <el-date-picker class="pform--content--row--value" v-model="form.originalTime" type="date" clearable
              @change="dateOfBirthChange" placeholder="请选择">
            </el-date-picker>
          </div>
          <div class="pform--content--row">
            <div class="pform--content--row--label">原案发生地点：</div>
            <el-cascader class="pform--content--row--value" placeholder="请选择" size="large" :options="options"
              v-model="originalLocationOptions" @change="originalLocationChange">
            </el-cascader>
          </div>
          <div class="pform--content--row">
            <div class="pform--content--row--label">原案案由：</div>
            <el-select class="pform--content--row--value" v-model="form.originalCauseOfAction" placeholder="请选择">
              <el-option v-for="item in [
                '强奸罪',
                '强制猥亵、侮辱罪',
                '儿童罪',
                '收买被拐卖的妇女儿童罪',
                '虐待罪',
                '遗弃罪',
                '拐骗儿童罪',
                '组织残疾人、儿童乞讨罪',
                '组织未成年人进行违反治安管理活动罪',
                '组织、强迫、引诱、容留、介绍卖淫罪',
                '传播淫秽物品罪',
                '组织淫秽表演罪',
                '虐待被监管人员罪',
                '其他',
              ]" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </div>
          <div class="pform--content--row pform--content--row_lg">
            <div class="pform--content--row--label">
              <span style="color: red">*</span>申诉内容：
            </div>
            <el-input class="pform--content--crow--value" type="textarea" :rows="6" placeholder="请输入内容"
              v-model="form.contentDesc" maxlength="2000" show-word-limit>
            </el-input>
          </div>
          <div class="pform--content--row pform--content--row_lg">
            <div class="pform--content--row--label">上传申诉状：</div>
            <el-upload action="https://fileprod.skillhub.top/file/imageFileUpload/" list-type="picture-card"
              class="pform--content--crow--value" :multiple="true" :limit="6" accept=".jpg,.png,.jpeg"
              :on-success="chargedMaterialOnSuccess" :on-remove="chargedMaterialOnRemove">
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
          <div class="pform--content--row pform--content--row_lg">
            <div class="pform--content--row--label">
              裁判文书或检察机关处理决定书：
            </div>
            <el-upload action="https://fileprod.skillhub.top/file/imageFileUpload/" list-type="picture-card"
              class="pform--content--crow--value" :multiple="true" :limit="6" accept=".jpg,.png,.jpeg"
              :on-success="evidenceMaterialOnSuccess" :on-remove="evidenceMaterialOnRemove">
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
          <div class="pform--content--row pform--content--row_lg">
            <div class="pform--content--row--label">复查文书：</div>
            <el-upload action="https://fileprod.skillhub.top/file/imageFileUpload/" list-type="picture-card"
              class="pform--content--crow--value" :multiple="true" :limit="6" accept=".jpg,.png,.jpeg"
              :on-success="otherMaterialsOnSuccess" :on-remove="otherMaterialsOnRemove">
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
          <div class="pform--content--row pform--content--row_lg">
            <div class="pform--content--row--label">申诉人身份证扫描件：</div>
            <el-upload action="https://fileprod.skillhub.top/file/imageFileUpload/" list-type="picture-card"
              class="pform--content--crow--value" :multiple="true" :limit="6" accept=".jpg,.png,.jpeg"
              :on-success="idCardScanningOnSuccess" :on-remove="idCardScanningOnRemove">
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
        </template>
        <template v-else-if="type == 2">
          <div class="pform--content--row">
            <div class="pform--content--row--label">
              <span style="color: red">*</span>请选择接收的检察院：
            </div>
            <el-cascader class="pform--content--row--value" placeholder="请选择" size="large" :options="onlyRegionData"
              v-model="contentDealOptions" @change="contentDealChange">
            </el-cascader>
          </div>
          <div class="pform--content--row">
            <div class="pform--content--row--label">被救助人类别：</div>
            <el-select class="pform--content--row--value" v-model="form.rescuedOneCategory" placeholder="请选择">
              <el-option v-for="item in [
                '刑事案件被害人',
                '民事案件被侵权人',
                '因案件陷入困境未成年人',
                '其他',
              ]" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </div>
          <div class="pform--content--row">
            <div class="pform--content--row--label">原案案由：</div>
            <el-select class="pform--content--row--value" v-model="form.originalCauseOfAction" placeholder="请选择">
              <el-option v-for="item in [
                '强奸罪',
                '强制猥亵、侮辱罪',
                '儿童罪',
                '收买被拐卖的妇女儿童罪',
                '虐待罪',
                '遗弃罪',
                '拐骗儿童罪',
                '组织残疾人、儿童乞讨罪',
                '组织未成年人进行违反治安管理活动罪',
                '组织、强迫、引诱、容留、介绍卖淫罪',
                '传播淫秽物品罪',
                '组织淫秽表演罪',
                '虐待被监管人员罪',
                '其他',
              ]" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </div>
          <div class="pform--content--row">
            <div class="pform--content--row--label">原办案检察机关名称：</div>
            <el-input class="pform--content--row--value" v-model="form.originalCaseName" placeholder="请输入"></el-input>
          </div>
          <div class="pform--content--row">
            <div class="pform--content--row--label">申请救助类别：</div>
            <el-select class="pform--content--row--value" v-model="form.applyForReliefCategory" placeholder="请选择">
              <el-option v-for="item in ['经济救助', '法律援助', '心理救助', '社会救助']" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </div>
          <div class="pform--content--row">
            <div class="pform--content--row--label">申请理由：</div>
            <el-input class="pform--content--row--value" v-model="form.applyForReason" placeholder="请输入"></el-input>
          </div>
          <div class="pform--content--row pform--content--row_lg">
            <div class="pform--content--row--label">原案情摘要：</div>
            <el-input class="pform--content--crow--value" type="textarea" :rows="6" placeholder="请输入"
              v-model="form.originalCaseAbstracts" maxlength="2000" show-word-limit>
            </el-input>
          </div>
          <div class="pform--content--row pform--content--row_lg">
            <div class="pform--content--row--label">
              <span style="color: red">*</span>申请救助内容：
            </div>
            <el-input class="pform--content--crow--value" type="textarea" :rows="6" placeholder="请输入"
              v-model="form.contentDesc" maxlength="2000" show-word-limit>
            </el-input>
          </div>
          <div class="pform--content--row pform--content--row_lg">
            <div class="pform--content--row--label">相关材料：</div>
            <el-upload action="https://fileprod.skillhub.top/file/imageFileUpload/" list-type="picture-card"
              class="pform--content--crow--value" :multiple="true" :limit="6" accept=".jpg,.png,.jpeg"
              :on-success="chargedMaterialOnSuccess" :on-remove="chargedMaterialOnRemove">
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
        </template>
        <div class="pform--content--row pform--content--row_lg" style="align-items: center; justify-content: center">
          <el-button style="width: 50%; margin-top: 30px; margin-bottom: 50px" type="primary" native-type="button"
            @click="submitForm()">提交</el-button>
        </div>
      </div>
    </div>
    <!-- 服务须知 -->
    <!-- <el-dialog
      title="提示"
      :show-close="false"
      :visible.sync="dialogVisible"
      width="50%"
      :modal="true"
      :modal-append-to-body="true"
      :append-to-body="true"
    >
      {{ tipText }}
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          round
          @click="dialogVisible = false"
          style="width: 320px"
          >确 定</el-button
        >
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
const nationDict = [
  "汉族",
  "壮族",
  "满族",
  "回族",
  "苗族",
  "维吾尔族",
  "土家族",
  "彝族",
  "蒙古族",
  "藏族",
  "布依族",
  "侗族",
  "瑶族",
  "朝鲜族",
  "白族",
  "哈尼族",
  "哈萨克族",
  "黎族",
  "傣族",
  "畲族",
  "傈僳族",
  "仡佬族",
  "东乡族",
  "高山族",
  "拉祜族",
  "水族",
  "佤族",
  "纳西族",
  "羌族",
  "土族",
  "仫佬族",
  "锡伯族",
  "柯尔克孜族",
  "达斡尔族",
  "景颇族",
  "毛南族",
  "撒拉族",
  "布朗族",
  "塔吉克族",
  "阿昌族",
  "普米族",
  "鄂温克族",
  "怒族",
  "京族",
  "基诺族",
  "德昂族",
  "保安族",
  "俄罗斯族",
  "裕固族",
  "乌孜别克族",
  "门巴族",
  "鄂伦春族",
  "独龙族",
  "塔塔尔族",
  "赫哲族",
  "珞巴族",
  "穿青人",
  "其他",
];
import { regionData, CodeToText } from "element-china-area-data";
export default {
  name: "inspect",
  data() {
    return {
      onlyRegionData: [
        {
          value: "520000",
          label: "贵州省",
          children: [
            {
              value: "520500",
              label: "毕节市",
              children: [
                {
                  value: "520502",
                  label: "七星关区",
                },
                {
                  value: "520521",
                  label: "大方县",
                },
                {
                  value: "520522",
                  label: "黔西市",
                },
                {
                  value: "520523",
                  label: "金沙县",
                },
                {
                  value: "520524",
                  label: "织金县",
                },
                {
                  value: "520525",
                  label: "纳雍县",
                },
                {
                  value: "520526",
                  label: "威宁县",
                },
                {
                  value: "520527",
                  label: "赫章县",
                },
              ],
            },
          ],
        },
      ],
      pageTitles: ["控告", "刑事申诉", "申请救助"],
      action2Titles: ["控告人信息", "申诉人信息", "申请人信息"],
      action3Titles: ["被控告人信息", "被申诉人信息"],
      action4Titles: ["控告事项", "申诉基本内容", "申请救助基本内容"],
      type: 0,
      nationDict: nationDict,
      options: regionData,
      censusRegisterSeatOptions: [],
      localOptions: [],
      schoolAddressOptions: [],
      applyInAreaOptions: [],
      personLocalOptions: [],
      contentAddressOptions: [],
      contentDealOptions: [],
      originalLocationOptions: [],
      form: {
        department: "检察",
        serviceType: "",
        nonageName: "",
        lawsuitStatus: "", // 原案中的诉讼关系
        nonageIdentity: "",
        nonageSex: "男",
        formerName: "",
        nickname: "",
        nonageDocumentType: "居民身份证",
        nonageDocumentNumber: "",
        dateOfBirth: "",
        nonageNation: "汉族",
        nonageNationality: "",
        censusRegisterSeat: "",
        local: "",
        detailedAddressOf: "",
        schoolName: "",
        schoolAddress: "",
        legalRepresentative: "",
        guardianshipIs: "父母监护",
        ageWhenCrimeHappened: "",
        applyName: "",
        applyRelation: "",
        applySex: "",
        applyDocumentType: "居民身份证",
        applyDocumentNumber: "",
        applyNation: "",
        applyNationality: "",
        applyPosition: "",
        applyInArea: "",
        applyAddress: "",
        applyIdentity: "",
        applyMobile: "",
        personName: "",
        personSex: "",
        personPosition: "",
        personIdentity: "",
        personOtherIdentity: "",
        personLocal: "",
        personAddress: "",
        personUnits: "",
        contentAddress: "",
        authorityType: "",
        authorityName: "",
        complaintCategory: "",
        originalTime: "",
        originalLocation: "",
        originalCauseOfAction: "",
        contentTime: "",
        contentDealProvince: "",
        contentDealCity: "",
        contentDealCounty: "",
        rescuedOneCategory: "",
        originalCaseName: "",
        applyForReliefCategory: "",
        applyForReason: "",
        originalCaseAbstracts: "",
        contentDesc: "",
        criminalCasesBy: "",
        otherCriminalCasesBy: "",
        chargedMaterial: "",
        evidenceMaterial: "",
        otherMaterials: "",
        idCardScanning: "",
      },
      dialogVisible: true,
      dialog: false,
      count: 0,
      tipText: "",
    };
  },
  mounted() {
    console.log("mounted", this.$route.query);
    this.type = this.$route.query.type ? parseInt(this.$route.query.type) : 0;
    this.tipText = this.$route.query.tipText;
    this.form.serviceType =
      this.type == 0 ? "控告" : this.type == 1 ? "刑事申诉" : "申请救援";
  },
  methods: {
    // 点击关闭弹框
    handleClose(done) {
      // this.dialog = false;
      this.count = 0;
    },
    censusRegisterSeatChange(value) {
      this.form.censusRegisterSeat = [
        CodeToText[value[0]],
        CodeToText[value[1]],
        CodeToText[value[2]],
      ].join(" ");
      console.log(this.form.censusRegisterSeat);
    },
    localHandleChange(value) {
      this.form.local = [
        CodeToText[value[0]],
        CodeToText[value[1]],
        CodeToText[value[2]],
      ].join(" ");
      console.log(this.form.local);
    },
    schoolAddressChange(value) {
      this.form.schoolAddress = [
        CodeToText[value[0]],
        CodeToText[value[1]],
        CodeToText[value[2]],
      ].join(" ");
      console.log(this.form.schoolAddress);
    },
    applyInAreaChange(value) {
      this.form.applyInArea = [
        CodeToText[value[0]],
        CodeToText[value[1]],
        CodeToText[value[2]],
      ].join(" ");
      console.log(this.form.applyInArea);
    },
    personLocalChange(value) {
      this.form.personLocal = [
        CodeToText[value[0]],
        CodeToText[value[1]],
        CodeToText[value[2]],
      ].join(" ");
      console.log(this.form.personLocal);
    },
    contentAddressChange(value) {
      this.form.contentAddress = [
        CodeToText[value[0]],
        CodeToText[value[1]],
        CodeToText[value[2]],
      ].join(" ");
      console.log(this.form.contentAddress);
    },
    originalLocationChange(value) {
      this.form.originalLocation = [
        CodeToText[value[0]],
        CodeToText[value[1]],
        CodeToText[value[2]],
      ].join(" ");
      console.log(this.form.originalLocation);
    },
    contentDealChange(value) {
      this.form.contentDealProvince = CodeToText[value[0]];
      this.form.contentDealCity = CodeToText[value[1]];
      this.form.contentDealCounty = CodeToText[value[2]];
      console.log(this.form.contentDealCounty);
    },
    dateOfBirthChange(e) {
      console.log(e);
    },
    chargedMaterialOnSuccess(response, file, fileList) {
      console.log("chargedMaterialOnSuccess", response, file, fileList);
      this.form.chargedMaterial = fileList
        .map((item, index) => {
          return item.response.data;
        }, this)
        .join(",");
      console.log(this.form.chargedMaterial);
    },
    chargedMaterialOnRemove(file, fileList) {
      this.form.chargedMaterial = fileList
        .map((item, index) => {
          return item.response.data;
        }, this)
        .join(",");
      console.log(this.form.chargedMaterial);
    },
    evidenceMaterialOnSuccess(response, file, fileList) {
      this.form.evidenceMaterial = fileList
        .map((item, index) => {
          return item.response.data;
        }, this)
        .join(",");
      console.log(this.form.evidenceMaterial);
    },
    evidenceMaterialOnRemove(file, fileList) {
      this.form.evidenceMaterial = fileList
        .map((item, index) => {
          return item.response.data;
        }, this)
        .join(",");
      console.log(this.form.evidenceMaterial);
    },
    otherMaterialsOnSuccess(response, file, fileList) {
      this.form.otherMaterials = fileList
        .map((item, index) => {
          return item.response.data;
        }, this)
        .join(",");
      console.log(this.form.otherMaterials);
    },
    otherMaterialsOnRemove(file, fileList) {
      this.form.otherMaterials = fileList
        .map((item, index) => {
          return item.response.data;
        }, this)
        .join(",");
      console.log(this.form.otherMaterials);
    },
    idCardScanningOnSuccess(response, file, fileList) {
      this.form.idCardScanning = fileList
        .map((item, index) => {
          return item.response.data;
        }, this)
        .join(",");
      console.log(this.form.idCardScanning);
    },
    idCardScanningOnRemove(file, fileList) {
      this.form.idCardScanning = fileList
        .map((item, index) => {
          return item.response.data;
        }, this)
        .join(",");
      console.log(this.form.idCardScanning);
    },
    back() {
      this.$router.back();
    },
    submitForm() {
      // 未成年人信息通用验证
      // 验证未成年人姓名
      if (this.isEmpty(this.form.nonageName)) {
        this.$elementMessage({
          showClose: false,
          message: "请输入未成年人姓名",
          type: "error",
        });
        return;
      }
      if (this.form.nonageDocumentType === "居民身份证") {
        let reg = /(^\d{15}$)|(^\d{17}([0-9]|X)$)/;
        if (!reg.test(this.form.nonageDocumentNumber)) {
          this.$elementMessage({
            showClose: false,
            message: "请输入正确的未成年人身份证号",
            type: "error",
          });
          return
        }
      } else if (this.form.nonageDocumentType === "户口本") {
        let reg = /^[a-zA-Z0-9]{3,21}$/;
        if (!reg.test(this.form.nonageDocumentNumber)) {
          this.$elementMessage({
            showClose: false,
            message: "请输入正确的未成年人户口本号码",
            type: "error",
          });
          return
        }
      }

      // 控告人信息通用验证
      // applyName
      // applyRelation
      // applyDocumentType
      // applyDocumentNumber
      // applyMobile
      // 控告人、申诉人、申请人信息验证

      // personName
      // personSex
      // personUnits

      if (this.type == 0) {
        // 验证控告人姓名
        if (this.isEmpty(this.form.applyName)) {
          this.$elementMessage({
            showClose: false,
            message: "请输入控告人姓名",
            type: "error",
          });
          return;
        }
        if (this.form.applyDocumentType === "居民身份证") {
          let reg = /(^\d{15}$)|(^\d{17}([0-9]|X)$)/;
          if (!reg.test(this.form.applyDocumentNumber)) {
            this.$elementMessage({
              showClose: false,
              message: "请输入正确的控告人身份证号",
              type: "error",
            });
            return
          }
        } else if (this.form.applyDocumentType === "户口本") {
          let reg = /^[a-zA-Z0-9]{3,21}$/;
          if (!reg.test(this.form.applyDocumentNumber)) {
            this.$elementMessage({
              showClose: false,
              message: "请输入正确的控告人户口本号码",
              type: "error",
            });
            return
          }
        } else if (this.form.applyDocumentType === "军官证") {
          let reg = /^[a-zA-Z0-9]{7,21}$/;
          if (!reg.test(this.form.applyDocumentNumber)) {
            this.$elementMessage({
              showClose: false,
              message: "请输入正确的控告人军官证号码",
              type: "error",
            });
            return
          }
        } else if (this.form.applyDocumentType === "士兵证") {
          let reg = /^[a-zA-Z0-9]{7,21}$/;
          if (!reg.test(this.form.applyDocumentNumber)) {
            this.$elementMessage({
              showClose: false,
              message: "请输入正确的控告人士兵证号码",
              type: "error",
            });
            return
          }
        } else if (this.form.applyDocumentType === "护照") {
          let reg = /^[a-zA-Z0-9]{3,21}$/;
          if (!reg.test(this.form.applyDocumentNumber)) {
            this.$elementMessage({
              showClose: false,
              message: "请输入正确的控告人护照号码",
              type: "error",
            });
            return
          }
        }
        let reg = /^1[3456789]\d{9}$/;
        if (!reg.test(this.form.applyMobile)) {
          this.$elementMessage({
            showClose: false,
            message: "请输入正确的控告人联系电话",
            type: "error",
          });
          return
        }
        // 被控告人信息
        if (this.isEmpty(this.form.personName)) {
          this.$elementMessage({
            showClose: false,
            message: "请输入被控告人姓名",
            type: "error",
          });
          return;
        }
        if (this.isEmpty(this.form.personSex)) {
          this.$elementMessage({
            showClose: false,
            message: "请选择被控告人性别",
            type: "error",
          });
          return;
        }
        if (this.isEmpty(this.form.personUnits)) {
          this.$elementMessage({
            showClose: false,
            message: "请输入被控告人工作单位",
            type: "error",
          });
          return;
        }
        // 控告内容
        if (this.isEmpty(this.form.contentDesc)) {
          this.$elementMessage({
            showClose: false,
            message: "请输入控告内容",
            type: "error",
          });
          return;
        }
        // 案发地
        if (this.contentAddressOptions == "") {
          this.$elementMessage({
            showClose: false,
            message: "请选择案发地",
            type: "error",
          });
          return;
        }
      } else if (this.type == 1) {
        if (this.isEmpty(this.form.lawsuitStatus)) {
          this.$elementMessage({
            showClose: false,
            message: "请选择在原案中的诉讼关系",
            type: "error",
          });
          return;
        }
        // 验证申诉人姓名
        if (this.isEmpty(this.form.applyName)) {
          this.$elementMessage({
            showClose: false,
            message: "请输入申诉人姓名",
            type: "error",
          });
          return;
        }

        // 与未成年人的关系
        if (this.isEmpty(this.form.applyRelation)) {
          this.$elementMessage({
            showClose: false,
            message: "请选择与未成年人的关系",
            type: "error",
          });
          return;
        }

        // 申诉人证件号码
        let reg = /(^\d{15}$)|(^\d{17}([0-9]|X)$)/;
        if (!reg.test(this.form.applyDocumentNumber)) {
          this.$elementMessage({
            showClose: false,
            message: "请输入正确的申诉人身份证号",
            type: "error",
          });
          return
        }
        // 申诉人联系电话
        reg = /^1[3456789]\d{9}$/;
        if (!reg.test(this.form.applyMobile)) {
          this.$elementMessage({
            showClose: false,
            message: "请输入正确的申诉人联系电话",
            type: "error",
          });
          return
        }

        // 被申诉人信息
        if (this.isEmpty(this.form.personName)) {
          this.$elementMessage({
            showClose: false,
            message: "请输入被申诉人姓名",
            type: "error",
          });
          return;
        }
        if (this.isEmpty(this.form.personSex)) {
          this.$elementMessage({
            showClose: false,
            message: "请选择被申诉人性别",
            type: "error",
          });
          return;
        }
        if (this.isEmpty(this.form.personUnits)) {
          this.$elementMessage({
            showClose: false,
            message: "请输入被申诉人工作单位",
            type: "error",
          });
          return;
        }
        if (this.isEmpty(this.form.contentDesc)) {
          this.$elementMessage({
            showClose: false,
            message: "请输入申诉内容",
            type: "error",
          });
          return;
        }
        // 验证在原案中的诉讼关系
        if (this.isEmpty(this.form.lawsuitStatus)) {
          this.$elementMessage({
            showClose: false,
            message: "请选择在原案中的诉讼关系",
            type: "error",
          });
          return;
        }
      } else if (this.type == 2) {
        // 验证申请人姓名
        if (this.isEmpty(this.form.applyName)) {
          this.$elementMessage({
            showClose: false,
            message: "请输入申请人姓名",
            type: "error",
          });
          return;
        }
        // 申诉人证件号码
        let reg = /(^\d{15}$)|(^\d{17}([0-9]|X)$)/;
        if (!reg.test(this.form.applyDocumentNumber)) {
          this.$elementMessage({
            showClose: false,
            message: "请输入正确的申请人身份证号",
            type: "error",
          });
          return
        }
        // 申诉人联系电话
        reg = /^1[3456789]\d{9}$/;
        if (!reg.test(this.form.applyMobile)) {
          this.$elementMessage({
            showClose: false,
            message: "请输入正确的申请人联系电话",
            type: "error",
          });
          return
        }

        if (this.isEmpty(this.form.contentDesc)) {
          this.$elementMessage({
            showClose: false,
            message: "请输入申请救助内容",
            type: "error",
          });
          return;
        }
      }
      if (this.isEmpty(this.form.contentDealCounty)) {
        this.$elementMessage({
          showClose: false,
          message: "请选择接收的检察院",
          type: "error",
        });
        return;
      }
      // this.$loading({
      //   text:' 正在保存'
      // })
      const loading = this.$loading.service({
        lock: true,
        text: "正在提交",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$HTTP
        .httpToken({
          url: "/shouwei/service/v2/save",
          data: {
            serviceData: this.form,
          },
          method: "post",
        })
        .then((res) => {
          loading.close();
          if (res.code == 0) {
            this.$elementMessage({
              showClose: false,
              message: "提交成功",
              type: "success",
            });
            this.$router.back();
          } else {
            this.$elementMessage({
              showClose: false,
              message: res.msg,
              type: "error",
            });
          }
        })
        .catch((err) => {
          loading.close();
          this.$elementMessage({
            showClose: false,
            message: err,
            type: "error",
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
.pcontent {
  width: 100%;
  height: auto;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pform {
  width: 100%;
  height: auto;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &--header {
    width: 100%;
    height: 60px;
    position: relative;
    border-bottom: 1px #dddddd solid;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &--back {
      position: absolute;
      left: 10px;
      color: #000;
      font-size: 16px;
    }

    &--title {
      left: 10px;
      color: #000;
      font-size: 24px;
    }
  }

  &--title {
    width: 100%;
    display: flex;
    padding: 0 10px;
    margin-top: 20px;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
    height: 50px;
    color: #000;
    font-size: 24px;
    border-bottom: 1px #dddddd solid;
    position: relative;

    &:before {
      content: "";
      width: 4px;
      height: 20px;
      margin-right: 10px;
      background: #0e9bd2;
      background-size: 10px 40px;
    }
  }

  &--content {
    width: 100%;
    position: relative;

    &--row_lg {
      width: 100% !important;
      display: flex;
      flex-direction: row;
      align-items: flex-start !important;
    }

    &--row {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      padding: 0 16px;
      box-sizing: border-box;
      margin-top: 16px;
      min-height: 50px;
      float: left;

      &--label {
        color: #000000;
        font-size: 16px;
        text-align: right;
      }

      &--value {
        flex: 1;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .pform {
    width: 100%;
  }

  .pform--content--row {
    width: 100%;
  }

  .pform--content--row--label {
    width: 80px;
  }
}

@media screen and (min-width: 800px) {
  .pform {
    width: 70%;
  }

  .pform--content--row {
    width: 50%;
  }

  .pform--content--row--label {
    width: 170px;
  }
}
</style>